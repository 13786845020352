import React, { useState, useEffect } from 'react';
// import './App_piperwai.css';
import { addToCart, changeCartItemQuantity } from './ShopifyCartApi';
import logEvent from './utils/logEvent';

function ShopifyQuantityAdjuster({ variantId, isExpandedProduct, cartData, getCartData, username, currentConv, token }) {
  const [quantity, setQuantity] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const item = cartData.find(item => Number(item.id) === Number(variantId));
    if (item) {
      setQuantity(item.quantity);
    }
    else {
        setQuantity(0);
    }
  }, [variantId, cartData]);

  const handleQuantityChange = (change) => {
    if (!isLoading){
        const newQuantity = quantity + change;
        if (newQuantity < 0) return; // Prevent negative quantities

        setIsLoading(true); // Indicate loading state
        const updatePromise = (newQuantity === 1 && change === 1) ? addToCart(variantId) : changeCartItemQuantity(variantId, newQuantity);

        updatePromise
            .then(response => {
              // console.log(change > 0 ? 'Added to cart:' : 'Updated cart quantity:', response);
              getCartData(); // Refresh cart data after operation
            })
            .catch(error => console.error('Error updating cart:', error))
            .finally(() => setIsLoading(false)); // Reset loading state

        setQuantity(newQuantity); // Optimistically set the new quantity

        const LogQuantityAdjustment = async () => {
          try {
            const eventData = {
              username: username,
              conversation_id: currentConv,
              url: window.location.href,
              event_type: 'quantity_adjustment',
              event_target: change > 0 ? "positive" : "negative",
            };
    
            await logEvent(eventData, token);
            // console.log('Event logged successfully');
          } catch (error) {
            // console.error('Error logging event:', error);
          }
        };
        LogQuantityAdjustment();
    }
  };

  const quantitySelector = (
      <div className="cart-product-card-quantity-selector">
        <svg onClick={() => handleQuantityChange(-1)} className={`cart-product-card-quantity-down-arrow ${quantity < 1 || isLoading ? 'disabled' : ''}`} xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
          <path d="M9 1L5 5L1 1" stroke="#8F959E" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <span> {quantity} </span>
        <svg onClick={() => handleQuantityChange(1)} className={`cart-product-card-quantity-up-arrow ${isLoading ? 'disabled' : ''}`} xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
          <path d="M1 5L5 0.999999L9 5" stroke="#8F959E" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
  );

  return isExpandedProduct ? (
      <div className='expanded-product-quantity-selector-wrapper'>
        {quantitySelector}
      </div>
  ) : quantitySelector;
}

export default ShopifyQuantityAdjuster;